import Link from 'next/link'
import Image from 'next/image'
import getConfig from 'next/config'
import handleViewport, { type InjectedViewportProps } from 'react-in-viewport'

import * as Styled from './styled'
/**
 * ui component
 */
import Icon from 'src/components/Icon'
import React from 'react'

const {
  publicRuntimeConfig: { STATIC_ASSETS_COMMON_URL },
} = getConfig()

export const Confirm = () => {
  return (
    <Styled.Container>
      <Styled.Content>
        <Styled.Inner>
          <Styled.Left>
            <Styled.Title>
              Подтверждайте свои <br />
              навыки сертификатом
            </Styled.Title>
            <Styled.Subtitle>
              Который станет сильным аргументом при устройстве на работу
            </Styled.Subtitle>

            <Link href='/catalogue' passHref>
              <Styled.Button>
                <Styled.ButtonText>Выбрать курс</Styled.ButtonText>
                <Icon name='arrow-right' />
              </Styled.Button>
            </Link>
          </Styled.Left>

          <Styled.Right>
            <Styled.Description>
              <Styled.Tag>от 70 000 ₽</Styled.Tag>
              <Styled.DescriptionText>
                будет начинаться ваша зарплата после прохождения курса
              </Styled.DescriptionText>
            </Styled.Description>

            <Styled.Image>
              <Image
                src={`${STATIC_ASSETS_COMMON_URL}/assets/images/light-main-confirm.png`}
                width={516}
                height={246}
                alt='background'
                quality={100}
              />
            </Styled.Image>
          </Styled.Right>
        </Styled.Inner>
      </Styled.Content>
    </Styled.Container>
  )
}

const ConfirmViewport = (props: InjectedViewportProps<HTMLDivElement>) => {
  const { forwardedRef, enterCount } = props
  return <div ref={forwardedRef}>{enterCount > 0 ? <Confirm /> : null}</div>
}
export default handleViewport(ConfirmViewport)
