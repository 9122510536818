import styled from 'styled-components'

export const Container = styled.section`
  position: relative;
  margin: 0 auto;
  padding: 23px 14.5px;
  max-width: 100%;
  width: 100%;

  @media ${({ theme }) => theme.devices.mobileLgUp} {
    padding: 25px 20px;
  }

  @media ${({ theme }) => theme.devices.noteUp} {
    max-width: 1440px;
    padding: 50px 108px 50px 108px;
  }
`

export const Content = styled.div`
  background: #f9fafb;
  border-radius: 20px;
  color: ${({ theme }) => theme.colors.black};
`

export const Inner = styled.div`
  margin: 0 auto;
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 26px 13px;
  overflow: hidden;

  @media ${({ theme }) => theme.devices.mobileLgUp} {
    flex-direction: row;
    max-width: 645px;
    padding: 25px 0;
    justify-content: space-between;
    gap: 25px;
  }

  @media ${({ theme }) => theme.devices.noteUp} {
    max-width: 1085px;
    padding: 45px 0;
    gap: 38px;
  }
`

export const Left = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;

  @media ${({ theme }) => theme.devices.noteUp} {
    max-width: 531px;
  }
`

export const Title = styled.h2`
  ${({ theme }) => theme.mixins.H4}

  @media ${({ theme }) => theme.devices.noteUp} {
    ${({ theme }) => theme.mixins.H2}
  }
`

export const Subtitle = styled.h3`
  margin-top: 4px;
  ${({ theme }) => theme.mixins.text3}

  @media ${({ theme }) => theme.devices.mobileLgUp} {
    margin-top: 7px;
    ${({ theme }) => theme.mixins.text4}
  }

  @media ${({ theme }) => theme.devices.noteUp} {
    margin-top: 12px;
    ${({ theme }) => theme.mixins.text1}
  }
`

export const Button = styled.a`
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 44px;
  width: 100%;
  border-radius: 50px;
  background: ${({ theme }) => theme.colors.primary};
  color: #fff;
  cursor: pointer;
  margin-top: 32px;
  padding: 12px 0;
  ${({ theme }) => theme.mixins.H6}

  @media ${({ theme }) => theme.devices.mobileLgUp} {
    margin-top: 30px;
    padding: 8px 18px;
    width: fit-content;
    ${({ theme }) => theme.mixins.text5}
    height: 28px;
    svg {
      width: 18px;
      height: 18px;
    }
  }

  @media ${({ theme }) => theme.devices.noteUp} {
    margin-top: 50px;
    padding: 12.5px 28px;
    ${({ theme }) => theme.mixins.H6}
    height: 44px;

    svg {
      width: 24px;
      height: 24px;
    }
  }
`

export const ButtonText = styled.p`
  margin-right: 8px;
  @media ${({ theme }) => theme.devices.mobileLgUp} {
    margin-right: 5px;
  }
  @media ${({ theme }) => theme.devices.noteUp} {
    margin-right: 8px;
  }
`
export const Right = styled.div`
  width: 100%;
  margin-top: 48px;
  padding-bottom: 173px;

  @media ${({ theme }) => theme.devices.mobileLgUp} {
    margin-top: 0;
    padding-bottom: 0;
  }
`

export const Description = styled.div`
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 12px;
  ${({ theme }) => theme.mixins.text3}

  @media ${({ theme }) => theme.devices.mobileLgUp} {
    flex-direction: row;
    align-items: center;
    gap: 8px;
    max-width: 258px;
    font-size: 10px;
    line-height: 15px;
  }

  @media ${({ theme }) => theme.devices.noteUp} {
    gap: 12px;
    max-width: 432px;
    ${({ theme }) => theme.mixins.text1}
  }
`

export const Tag = styled.div`
  padding: 9px 21px;
  width: 127px;
  background: linear-gradient(123.04deg, #8decb3 -0.82%, #b3edff 128.21%);
  border-radius: 8px;
  flex-shrink: 0;
  text-align: center;
  ${({ theme }) => theme.mixins.H6}

  @media ${({ theme }) => theme.devices.mobileLgUp} {
    padding: 6px;
    font-size: 10px;
    line-height: 14px;
    width: 76px;
  }
  @media ${({ theme }) => theme.devices.noteUp} {
    padding: 10px 10.5px;
    ${({ theme }) => theme.mixins.H5}
    width: 127px;
  }
`
export const DescriptionText = styled.div``

export const Image = styled.div`
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 300px;
  height: 145.9px;

  @media ${({ theme }) => theme.devices.mobileLgUp} {
    position: relative;
    bottom: initial;
    left: initial;
    transform: initial;
    margin-top: 24px;
  }
  @media ${({ theme }) => theme.devices.noteUp} {
    margin-top: 39px;
    width: initial;
    height: initial;
  }
`
